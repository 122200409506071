module.exports = [{
      plugin: require('/Users/cgenco/Projects/3hats/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/cgenco/Projects/3hats/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/cgenco/Projects/3hats/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/cgenco/Projects/3hats/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
